import * as React from "react";
import axios from "axios/index";
import "./styles/SearchForm.css"
import Config from '../../../Config';

class SearchForm extends React.Component{



    constructor(props){
        super(props);
        this.onLocationFieldChange.bind(this);
        this.onLocationSelected.bind(this);
        this.handleOnChange.bind(this);
        this.onRequestLocationClick.bind(this);
        this.state = {
            "searching": false,
            "nameSearching": false,
            "hasNameTyped": false,
            "locationFieldsIsOnFocus": false,
            "nameFieldsIsOnFocus": false,
            "searchString": [],
            "nameSearchString": [],
            "categoriesResult": [],
            "location" : "",
            "company_name" : ""
        };

    }


    handleLocationOnFocus = () => {
        this.setState({locationFieldsIsOnFocus: true});
        this.setState({nameFieldsIsOnFocus: false});
    };

    handleNameOnFocus = () => {
        this.setState({nameFieldsIsOnFocus: true});
        this.setState({locationFieldsIsOnFocus: false});
    };

    handleLocationOnBlur = (e) => {

        const key = e.target.value;
        if (key.length <= 0) {
            //this.setState({locationFieldsIsOnFocus: false});
            this.setState({ searchString: [] });
        }
    };

    handleNameOnBlur = (e) => {

        const key = e.target.value;
        if (key.length <= 0) {
            this.setState({nameFieldsIsOnFocus: false});
            this.setState({ searchString: [] });
            this.setState({hasNameTyped: false});
        }
    };

    onLocationSelected = () => {
        this.setState({locationFieldsIsOnFocus: false});
        this.setState({searchString: []});
    };

    onNameSelected = () => {
        this.setState({nameFieldsIsOnFocus: false});
        this.setState({searchString: []});
        this.setState({hasNameTyped: false});
    };

    handleOnChange = e =>{
        const { name, value } = e.target;
        this.setState(prevstate => ({ [name]: value}));


        if(name === 'location'){



            const key = e.target.value;

            if (key.length > 2) {
                this.setState({searching: true});

                axios.get(Config.links.location_search, {params: {key: key}})
                    .then((response) => {
                        //console.log(response.data);
                        this.setState({searchString: response.data});
                        this.setState({searching: false});
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({searching: false});
                    })
            }
        }

        if(name === 'company_name'){
            const key = e.target.value;

            if (key.length > 2) {
                this.setState({nameSearching: true});
                this.setState({hasNameTyped: true});
                axios.get(Config.links.business_name_search, {params: {key: key}})
                    .then((response) => {
                        console.log(response.data);
                        this.setState({nameSearchString: response.data.business});
                        this.setState({categoriesResult: response.data.categories});
                        this.setState({nameSearching: false});
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({nameSearching: false});
                    })
            }else {
                this.setState({hasNameTyped: false});
            }
        }
    };

    componentDidMount() {
        this.setState({searchString: []});
        //window.requestLocation();

    }

    onRequestLocationClick = e =>{
        e.preventDefault();
        //window.requestLocation();
        navigator.geolocation.getCurrentPosition(function () {}, function () {}, {});
        navigator.geolocation.getCurrentPosition(function (position) {
            window.getReverseGeocodingData(position.coords.latitude , position.coords.longitude);

        }, function (e) {
            console.log(e);
        }, {
            enableHighAccuracy: true
        });
    };







    onLocationFieldChange = async (fieldName) => {



        const key = fieldName.target.value;

        if (key.length > 2) {
            this.setState({searching: true});
            axios.get('http://192.168.54.102/admin.goinglocal/search', {params: {key: key}})
                .then((response) => {
                    console.log(response.data);

                    this.setState({searchString: response.data});
                    this.setState({searching: false});
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({searching: false});
                })
        }


    };

    render(){
        return(
            <div className=" search-form-holder ">


                <div className="row white" id="search-form" style={{ maxWidth: 749, margin: 'auto'}}>
                    <div className="col s12 m4 l4 location-field-col">
                        <div className="location-search-field-holder v">


                            {
                                !this.state.searching ?
                                    <i className="material-icons dp48">location_on</i> :
                                    <div className="preloader-wrapper small active location-preloader">
                                        <div className="spinner-layer spinner-blue">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="gap-patch">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                        <div className="spinner-layer spinner-red">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="gap-patch">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                        <div className="spinner-layer spinner-yellow">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="gap-patch">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                        <div className="spinner-layer spinner-green">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="gap-patch">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </div>

                            }


                            <input name="location" autoComplete="off" id="locationField"
                                   onFocus={this.handleLocationOnFocus}
                                   onBlur={e => this.handleLocationOnBlur(e)}
                                   onChange={ evt => this.handleOnChange(evt)}
                                   type="text"
                                   required={true}
                                   className="browser-default"
                                   placeholder="Suburb or Postcode"/>
                        </div>
                    </div>
                    <div className="col s12 m5 l5 business-field-col">
                        <div className="business-search-field-holder ">

                            {
                                !this.state.nameSearching ?
                                    <i className="material-icons dp48">search</i> :
                                    <div className="preloader-wrapper small active location-preloader">
                                        <div className="spinner-layer spinner-blue">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="gap-patch">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                        <div className="spinner-layer spinner-red">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="gap-patch">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                        <div className="spinner-layer spinner-yellow">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="gap-patch">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                        <div className="spinner-layer spinner-green">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="gap-patch">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </div>

                            }

                            <input

                                onFocus={this.handleNameOnFocus}
                                onBlur={e => this.handleNameOnBlur(e)}
                                required={true}
                                onChange={evt => this.handleOnChange(evt)}
                                name="company_name"
                                id="businessNameField"
                                autoComplete="off"
                                type="text"
                                className="browser-default"
                                placeholder="Business category or name"/>
                        </div>
                    </div>

                    <div className="col s12 m3 l3 hide-on-small-and-down green">
                        <div className="submit-search-field-holder width-100">
                            <button type="submit"  onSubmit={event => this.prop.handleOnSubmit(event)}
                                    className="btn btn-flat white-text btn-large green  waves-effect waves-circle waves-color-demo">SEARCH
                            </button>
                        </div>
                    </div>


                </div>

                <div id="searchProgress">

                </div>



                {
                    this.state.nameFieldsIsOnFocus ?
                        <div className="results-section grey lighten-3" >




                            <div id="result-list">

                                <div className="items business">

                                    <p className="pl-3 green-text mt-1 mb-1" style={{ fontSize: 13}}>
                                        <i className="material-icons  left mr-1" style={{ fontSize: 16}}>list</i>
                                        Categories
                                    </p>
                                    {
                                        this.state.categoriesResult.length > 0 ?
                                            this.state.categoriesResult.map((item, index) => {
                                                return (<p className="business-result-list-item "
                                                           onClick={this.onNameSelected}
                                                           name={item.name} key={index}>{item.name}</p>);

                                            }) : <p className="pl-3 mt-1">No results</p>
                                    }


                                    <p className="pl-3 green-text mt-1 mb-1" style={{ fontSize: 13}}>
                                        <i className="material-icons  left mr-1" style={{ fontSize: 16}}>business</i>
                                        Business
                                    </p>
                                    {
                                        this.state.nameSearchString.length > 0 ?

                                            this.state.nameSearchString.map((item, index) => {
                                                return (<p className="business-result-list-item "
                                                           onClick={this.onNameSelected}
                                                           name={item.name} key={index}>{item.name}</p>);

                                            })
                                            : <p className="pl-3 mb-1 mt-1">No results</p>
                                    }






                                    <div className="row">
                                        <div className="col s12 m12 l12 no-padding">

                                        </div>
                                        <div className="col s12 m12 l12 no-padding ">

                                        </div>
                                    </div>



                                </div>



                            </div>

                        </div> : ''
                }


                {
                    this.state.locationFieldsIsOnFocus ?
                        <div className="results-section grey lighten-3 " style={{ margin: 'auto'}}>
                            <div id="result-list">
                                <div className="items">
                                    {
                                        this.state.searchString.map((item, index) => {
                                            return (<p className="location-result-list-item"
                                                       onClick={this.onLocationSelected}
                                                       suburb={item.suburb+" "+item.state+" "+item.postcode} key={index}>{item.suburb} {item.state} {item.postcode}</p>);

                                        })
                                    }
                                </div>

                            </div>

                            <div className="location-request-holder">
                                <div><a href="#" style={{ cursor: 'pointer'}} onClick={e => this.onRequestLocationClick(e)} id="requestLocationBtn" className="green-text"> <i
                                    className="material-icons dp48">location_searching</i> Use My
                                    Location</a></div>
                                <p style={{marginTop: 0}}></p>
                            </div>
                        </div> : ''
                }

                <div className="row">
                    <div className="col s12 m3 l3 hide-on-med-and-up green">
                        <div className="submit-search-field-holder green">
                            <button type="submit"  onSubmit={event => this.prop.handleOnSubmit(event)}
                                    className="btn btn-flat white-text btn-large green  waves-effect waves-circle waves-color-demo">SEARCH
                            </button>
                        </div>
                    </div>
                </div>


            </div>

        );
    }
}

export default SearchForm;