import React, {useEffect, useState} from 'react';
import UserSession from "../../utils/UserSession";
import axios from "axios/index";
import Config from "../../Config";
import {useNavigate, useParams} from "react-router-dom";

export default function UnverifiedPage(props) {


    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const  verified = queryParams.get("verified");
    useEffect(()=>{

        //alert(verified);

        if(!UserSession.getToken()){
            navigate('/login', {replace: true})
        }else {







            if(UserSession.getUserData().email_verified_at != null){
                navigate('/user/profile', {replace: true})
            }else {


                const formData = new FormData();
                formData.append('userID' , UserSession.getUserData().id);
                const config = {
                    headers: { Authorization: `Bearer ${UserSession.getToken()}` }
                };
                axios.post(Config.links.checkIfEmailVerified, formData,config)
                    .then(function (response) {
                        console.log(response);
                        if(response.data.message === 'ok'){
                            let userData = UserSession.getUserData();
                            userData.email_verified_at = response.data.email_verified_at;
                            UserSession.setUserData(userData);
                            navigate('/user/profile', {replace: true})
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });




            }

        }
    }, []);


    const screenheight = window.innerHeight;

    const HandleClick = (e)=>{
        e.preventDefault();

        window.$("#resendLink").prop('disabled', true);
        window.$("#resendLink").off('click');
        window.$("#resendLink").text("Sending please wait...");
        const formData = new FormData();
        formData.append('userID' , UserSession.getUserData().id);
        const config = {
            headers: { Authorization: `Bearer ${UserSession.getToken()}` }
        };
        axios.post(Config.links.resendVerificationEmail, formData,config)
            .then(function (response) {
                console.log(response);
                window.$(".resenttxt").hide();
                window.$(".reulttxt").text('A fresh verification link has been sent to your email address.');
            })
            .catch(function (error) {
                 console.log(error);
                 window.$(".resenttxt").hide();
            });

    };



    return(
        <div className="container" id="unverifiedPage" style={{ minHeight: screenheight -194 , paddingTop: 50, paddingBottom: 50}}>
            <div className="row">
                <div className="col s12 m12 l12 center">
                    <h4>Verify Your Email Address</h4>
                    <p>Before proceeding, please check your email for a verification link.<br/> <span className="resenttxt">If you did not receive the email, <a href="#" id="resendLink" className="green-text" onClick={e => HandleClick(e)}>click here to request another.</a></span></p>
                    <p className="reulttxt black-text"></p>

                    <p className="mt-3">Please reload this page if you already verify your email.</p>
                </div>
            </div>
        </div>
    )

}