import react from 'react';

export default function TermsOfUse(props) {
    return(
        <div id="tou">
            <div className="container" style={{ paddingTop: 50, paddingBottom: 50}}>
                <div className="row">
                    <div className="col s12">

                        <h2 className="center mb-3">TERMS OF USE</h2>

                        <p>
                            These Terms of Use (‘Terms’) apply to all visitors and users of this website. We may modify and update these Terms at any time, without notice. You need to ensure you review the Terms from time to time. In using our website and services, you agree to be bound by these Terms as well as any and all general Terms and Conditions posted on our website from time to time.

                            <br/><br/>

                            DEFINITIONS
                            <br/><br/>


                            “Advertiser” a provider of Services that we permit to advertise on the website.

                            <br/><br/>

                            “Mini-site” is the one page site we set up on the website for each Advertiser.

                            <br/><br/>

                            “Content” means any and all material, links, words, moving and still images, videos, data and anything else that forms part of our Services to you.

                            <br/><br/>

                            “Service” or “Services” means the provision of professional services in any of the following categories of services:

                            <br/><br/>

                            Accommodation<br/>

                            Accountants<br/>

                            Acupuncture<br/>

                            Aged Care Services<br/>

                            Airconditioning - Automotive<br/>

                            Airconditioning - Commercial<br/>

                            Aircondotioning - Residential<br/>

                            Airport Shuttle Services<br/>

                            Alternative & Natural Therapies<br/>

                            Amusement Centres<br/>

                            Animals & Pet Services<br/>

                            Antennas<br/>

                            Aquariums & Supplies<br/>

                            Architects<br/>

                            Art Galleries<br/>

                            Art Schools<br/>

                            Asbestos Removal<br/>

                            Auctioneers<br/>

                            Audiovisual Equipment & Productions<br/>

                            Auto Electrical Services<br/>

                            Awnings<br/>

                            Baby Products<br/>

                            Baby Sitters<br/>

                            Bakery<br/>

                            Balustrading<br/>

                            Banking & Financial Services<br/>

                            Barbecues & Equipment<br/>

                            Bathroom Renovations<br/>

                            Barbers<br/>

                            Barristers<br/>

                            Bathroom Renovations<br/>

                            Batteries Automotive<br/>

                            Beauty Salons<br/>

                            Beds & Bedding<br/>

                            Bicycles - Accessories & Repairs<br/>

                            Blinds<br/>

                            Boat Charter Services<br/>

                            Boat Hire<br/>

                            Boat & Jetski Sales<br/>

                            Bookkeeping Services<br/>

                            Books - Retail<br/>

                            Boutiques<br/>

                            Bowling Clubs<br/>

                            Brakes & Clutch Services<br/>

                            Brick Cleaning<br/>

                            Bricklayers<br/>

                            Bridal Wear - Hire<br/>

                            Bridal Wear - Retail<br/>

                            Butchers<br/>

                            Building & Construction<br/>

                            Cabinet Makers<br/>

                            Cafes<br/>

                            Cakes<br/>

                            Cake Decoration Supplies<br/>

                            Cars - New<br/>

                            Cars - Used<br/>

                            Car Detailing<br/>

                            Car Hire<br/>

                            Car Rental<br/>

                            Car Wash Cafe<br/>

                            Car Parking<br/>

                            Caravan - New<br/>

                            Caravan - Used<br/>

                            Caravan - Hire<br/>

                            Carpenters<br/>

                            Carpet & Flooring<br/>

                            Carpet Cleaning<br/>

                            Carports & Pergolas<br/>

                            Cartridges - Ink & Toner<br/>

                            Catering & Food<br/>

                            Celebrant Services<br/>

                            Childcare Centres<br/>

                            Children Parties<br/>

                            Chiropractors<br/>

                            Churches, Mosques & Temples<br/>

                            Cinemas<br/>

                            City Councils<br/>

                            Cleaning Contractors<br/>

                            Cleaning - Homes<br/>

                            Cleaning Products & Supplies<br/>

                            Clothing<br/>

                            Clothing Alterations<br/>

                            Clubs & Pubs<br/>

                            Computer Equipment & Repairs<br/>

                            Concrete Contractors<br/>

                            Concrete Sawing<br/>

                            Councils<br/>

                            Conveyancing Services<br/>

                            Coolrooms<br/>

                            Cosmetic Surgery<br/>

                            Councilling Services<br/>

                            Courier Services<br/>

                            Crafts Retail<br/>

                            Crane Hire<br/>

                            Custom brokers<br/>

                            Dance Studio<br/>

                            Debt Collection Service<br/>

                            Delicatessens<br/>

                            Dentist<br/>

                            Disability Services<br/>

                            DJ mobile<br/>

                            Doctors - General Practice<br/>

                            Dog & Cat Grooming<br/>

                            Dog Training<br/>

                            Door and Gate Equipment<br/>

                            Drafting Service<br/>

                            Drainers<br/>

                            Driving School<br/>

                            Dry Cleaners<br/>

                            Education & Learning<br/>

                            Electrical Appliances<br/>

                            Electrical Appliances Repairs<br/>

                            Electricians<br/>

                            Embroidery Services<br/>

                            Emergency Services<br/>

                            Employment Services<br/>

                            Energy<br/>

                            Engineers<br/>

                            Engraving Services<br/>

                            Entertainment Promoters<br/>

                            Event Management<br/>

                            Events & Festivals<br/>

                            Excavating & Earth Moving Contractors<br/>

                            Fashion - Kids<br/>

                            Fashion - Mens<br/>

                            Fashion - Womens<br/>

                            Fencing Contractor<br/>

                            Finance Brokers<br/>

                            Finance Mortgage Loans<br/>

                            Finance Motor Vehicles<br/>

                            Financial Planning<br/>

                            Fire Protection Equipment<br/>

                            First Aid Supplies<br/>

                            Fish & Seafood Retail<br/>

                            Fishing Tackle<br/>

                            Fishing Charters<br/>

                            Floor Coverings<br/>

                            Floor Sanding & Polishing<br/>

                            Floors - Timber<br/>

                            Florist<br/>

                            Food & Beverages<br/>

                            Foreign Currency Exchanges<br/>

                            Formal Wear Hire - Mens<br/>

                            Four Wheel Drive Equipment & Accessories<br/>

                            Function Centres<br/>

                            Funeral Services<br/>

                            Furniture - Commercial<br/>

                            Furniture - Outdoor<br/>

                            Furniture - Retail<br/>

                            Furniture Removalist<br/>

                            Furniture Restoration & Repairs<br/>

                            Garage Doors<br/>

                            Gardeners<br/>

                            Gasfitters<br/>

                            Gas Appliances & Equipment<br/>

                            Gates<br/>

                            Gazebos<br/>

                            Glass Window Repairs - Home<br/>

                            Glass Window Repairs - Windscreens<br/>

                            Go Kart Tracks<br/>

                            Government Services<br/>

                            Granny Flats<br/>

                            Graphic Designers<br/>

                            Greengrocers<br/>

                            Gutter Cleaning<br/>

                            Guttering<br/>

                            Gymnastic Clubs<br/>

                            Hairdressers<br/>

                            Handbags<br/>

                            Hardware & Tools<br/>

                            Health Care Services NDIS<br/>

                            Health & Fitness Centres<br/>

                            Health Foods & Products<br/>

                            Health Insurance<br/>

                            Hearing Aids, Equipment & Services<br/>

                            Heating Appliances<br/>

                            Hi-Fi Equipment<br/>

                            Handyman Services<br/>

                            Home Health Care Aids & Equipment<br/>

                            Home Improvements<br/>

                            Hospitals - Private<br/>

                            Hospitals - Public<br/>

                            Hot Water Systems<br/>

                            Hypnotheraphy<br/>

                            Indoor Sports<br/>

                            Insurance Brokers<br/>

                            Interior Decorators

                            Irrigation Systems<br/>

                            Jewellers<br/>

                            Joinery<br/>

                            Jumping Castles<br/>

                            Karaoke<br/>

                            Kitchen Renovations<br/>

                            Landscape Contractors & Designers<br/>

                            Landscape Supplies<br/>

                            Laundries Services<br/>

                            Lawn Mowing Service<br/>

                            Lawn Mowers Retail & Repairs<br/>

                            Lawn & Turf Supplies<br/>

                            Lawyer<br/>

                            Legal Support Services<br/>

                            Letterbox Distribution<br/>

                            Libraries<br/>

                            Life Coaching<br/>

                            Lighting & Accessories - Retail<br/>

                            Limousine Hire<br/>

                            Liquor Store - Retail<br/>

                            Locksmith<br/>

                            Marinas<br/>

                            Martial Arts<br/>

                            Massage Therapy<br/>

                            Mechanical Engineers<br/>

                            Media & Communications<br/>

                            Medical Centres<br/>

                            Migration Consultants<br/>

                            Mobile Phone Repairs<br/>

                            Mobile Phones & Accessories<br/>

                            Mortgage Brokers<br/>

                            Motor Accessories - Retail<br/>

                            Motor Car Auctions<br/>

                            Motor Mechanics<br/>

                            Motor Mechanics - Mobile<br/>

                            Motorcycle Parts & Accessories<br/>

                            Motorcycles - New<br/>

                            Motorcycles - Used<br/>

                            Mufflers & Exhaust<br/>

                            Museums<br/>

                            Music Store - Retail<br/>

                            Music Lessons<br/>

                            Nail Salon<br/>

                            Naturopaths<br/>

                            Newsagents<br/>

                            Nightclubs<br/>

                            Nurseries - Retail<br/>

                            Nursing Homes<br/>

                            Office Supplies<br/>

                            Optometrists<br/>

                            Orthopaedic<br/>

                            Outboard Motors - New<br/>

                            Outboard Motors - Repairs<br/>

                            Painters & Decorators<br/>

                            Party Equipment Hire<br/>

                            Patent Attorneys<br/>

                            Personal Fitness Instructor<br/>

                            Pest Control<br/>

                            Pet Care Services<br/>

                            Pet Shops<br/>

                            Pharmacies & Chemists<br/>

                            Photocopying Services<br/>

                            Photographers<br/>

                            Physiotherapists<br/>

                            Plasterers<br/>

                            Plumbers<br/>

                            Podiatrists<br/>

                            Power Tools - Retail<br/>

                            Pressure Washing<br/>

                            Pre Schools<br/>

                            Printers<br/>

                            Professional Services<br/>

                            Property Management<br/>

                            Psychologists<br/>

                            Real Estate - Residential<br/>

                            Real Estate - Commercial & Industrial<br/>

                            Refrigeration - Commercial & Industrial<br/>

                            Refrigeration - Residential<br/>

                            Restaurants<br/>

                            Roller Shutters<br/>

                            Roof Restoration & Cleaning<br/>

                            Rubbish Removal<br/>

                            Scaffolding<br/>

                            Security Doors, Windows & Equipment<br/>

                            Security Guards & Patrol

                            Security Cameras<br/>

                            Security Alarms<br/>

                            Skin Treatment<br/>

                            Signage<br/>

                            Skip Bins<br/>

                            Skylights<br/>

                            Smash Repairer<br/>

                            Solicitors & Lawyers<br/>

                            Solar Energy<br/>

                            Sport Centres<br/>

                            Spray Tanning<br/>

                            Sporting Goods & Equipment<br/>

                            Squash Courts<br/>

                            Stonemason & Stonework<br/>

                            Storage<br/>

                            Strata Title Management<br/>

                            Supermarkets and Grocery Stores<br/>

                            Swimming Pool Construction<br/>

                            Swimming Lessons<br/>

                            Swimming Pools - Public<br/>

                            Swimming Pool Equipment & Chemicals<br/>

                            Telecommunications<br/>

                            Takeaway Foods<br/>

                            Tattoo Salon<br/>

                            Tennis Coaches<br/>

                            Tennis Court Hire<br/>

                            Ten Pin Bowling<br/>

                            Theatres<br/>

                            Tilers<br/>

                            Towing Services<br/>

                            Toys - Retail<br/>

                            Trailers & Equipment<br/>

                            Trailer Hire<br/>

                            Travel Agents<br/>

                            Transport Services<br/>

                            Tree Lopping & Stump Grinding<br/>

                            Tutoring<br/>

                            Tyres<br/>

                            Upholsterers<br/>

                            Utility Providers<br/>

                            Vacuum Cleaners - Residential<br/>

                            Veterinary Clinics<br/>

                            Wallpapers & wallcoverings<br/>

                            Wardrobes - Built Ins<br/>

                            Waterproofing Contractors<br/>

                            Websites<br/>

                            Wedding Cakes<br/>

                            Wedding Cars<br/>

                            Wedding Flowers<br/>

                            Wedding Hair & Beauty Services<br/>

                            Wedding Music & Entertainment<br/>

                            Wedding Photographer<br/>

                            Wedding Reception Venues<br/>

                            Weight Loss Treatment<br/>

                            Wheel Alignment & Balancing<br/>

                            Window Cleaning<br/>

                            Window Tinting<br/>

                            Yoga<br/>

                            <br/><br/>

                            “the website” means www.goinglocal.com.au

                            <br/><br/>

                            “We”, “our” and “us” means Goinglocal™ (ABN 35 763 402 146).

                            <br/><br/>

                            “You” means the visitor or user of our services and by doing so, agree to these Terms.

                            <br/><br/>

                            AGREEMENT FOR OUR SERVICES

                            <br/><br/>

                            To be eligible to use our Services, you acknowledge and agree to the following:

                            <br/><br/>

                            You are not permitted to share, copy, reproduce, use or make a derivative of all or any part of the Content or any on our website at any time unless expressly permitted in writing. Any other use is strictly prohibited.

                            <br/><br/>

                            You are not permitted to tamper, alter or otherwise circumvent any of the technology, Content or Services we provide in any format.


                            <br/><br/>
                            You engage and use the Services at your own risk and agree to hold us harmless from any and all claims that may be brought against us as a direct or indirect result of providing the Services to you.


                            <br/><br/>
                            You agree you will not transmit any viruses, malware, worms, etc. of any kind and that you will not upload, post, host or transmit unsolicited material or messages to the website.

                            <br/><br/>

                            GENERAL


                            <br/><br/>
                            We make no warranty that the website services will meet your requirements or be available on an uninterrupted, secure or error-free basis. We will use our best endeavors to ensure the website is always available and virus free but from time-to-time, and in some instances, this may not be the case as it may be out of our immediate control. We will endeavor to notify you if the website becomes unavailable for any lengthy and unusual time period.


                            <br/><br/><br/><br/>
                            DUE DILIGENCE

                            <br/><br/>

                            We are a third party facilitator only to enable individuals and businesses to place their advertisement for their professional services. We do not pre-screen or pre-approve all Content and we are not responsible to the Content that is provided by each Advertiser.

                            <br/><br/>

                            We are not acting as a contractor or agent of any Advertiser and we are not responsible for the quality of any work done by an Advertiser. We provide no warranties or guarantees in relation to any Advertiser or their services.

                            <br/><br/>

                            While we require Advertisers to verify their ABN registration, which we review prior to adding the Advertiser to the website, at no time are we responsible for any acts or omissions of any Advertiser, including the acts or omissions of any employee, independent contractor or company director of an Advertiser. You need to do your due diligence and make your own enquiries to determine if the service provider has the skill and experience and is suitable for your purposes and personal situation.

                            <br/><br/>

                            FORUM RULES

                            <br/><br/>

                            By using our website and contributing to or participating in any comments or forums, you agree to the following Terms:

                            <br/><br/>

                            Any recommendations, comments, information, images, videos or posts (together “Posts”) you make to our website will abide by our terms and will be made in the spirit, culture and ethos of the website;

                            <br/><br/>

                            You agree you will only make correct, accurate Posts in good faith and will not use aggressive, defamatory, rude, or offensive language. We will not condone bad language nor will we permit false claims, defamation, harassment, fraud, collusion, or Posts that may offend any person, visitor, or other third party. We can at any time, in our sole discretion, remove any Post and block your access to our website without notice;

                            <br/><br/>

                            Posts are not permitted that:

                            <br/><br/>

                            encourage illegal intent

                            <br/><br/>

                            contain ads, links or other forms of advertising

                            <br/><br/>

                            solicit business

                            <br/><br/>

                            are abusive or malicious against any other participant, our staff or any third party;

                            <br/><br/>

                            We will also take action, where required, against any person that does not abide by these terms in relation to Posts and where any action is taken against us by any person, visitor or any or third party as a result of your Post;

                            <br/><br/>

                            You may post recommendations, businesses you have used the services of, experiences and other information to the Website. You agree to only do so in good faith, with honest, fair and non-offensive reviews, using appropriate language, truthful descriptions and helpful comments;

                            <br/><br/>

                            The posting of comments, reviews and your experiences on the Website is meant to try to help other visitors to the website, and to share your guidance and information. Our aim is to encourage helpful comments and feedback and hope that you will assist, benefit and enjoy this initiative. We ask that you carefully consider any comments you may post and the impact they may have in a respectful manner;

                            <br/><br/>

                            In particular, please be respectful and considerate to businesses and other users. Any offensive, abusive, rude, untruthful, defamatory, or otherwise objectionable material will be immediately deleted and your access to the website may be cancelled without warning in our sole discretion;

                            <br/><br/>

                            You understand and agree that we may review and delete any content, including but not limited to images, messages, photos, profiles or material that in our sole judgment violates these Terms, or that might be offensive or illegal, or that might violate the rights of, harm, or threaten the safety of users. You may be banned from using the website at any time and in our sole discretion;

                            <br/><br/>

                            Opinions, advice and all other information expressed by participants in discussions or Posts are those of the author and not of Goinglocal™ or its officers, employees or independent contractors or anyone associated with the website. You rely on any such information at your own risk and need to make your own enquiries before taking any action related to any Post; and

                            <br/><br/>

                            Any action you choose to take with any other member or person on or through this website, including but not limited to the provision of your personal information, telephone, email, arranging a meeting or other activities with anyone on or through this website is at your own risk. We are at no time responsible for or liable for any action you choose to take under any circumstance.

                            <br/><br/>

                            USER REVIEWS & RATINGS (FEEDBACK)


                            <br/><br/>
                            If you decide to leave a review & rating, you do so at your own risk. All reviews & ratings are provided ‘as is’, and we are under no obligation to monitor any posts. We are not responsible for any posts, ratings & reviews that you or any other third party wish to make available. The views and opinions that are expressed in posts contributed by third parties are not representations made by us, nor do we endorse or do we share the same views or opinions that has been expressed. We make absolutely no representations about the accuracy or suitability of any posts. You are encouraged to make your own enquiries and seek your own independent advice before relying on any posts, reviews or ratings, or any other third party content being published.

                            <br/><br/>

                            You are liable and agree to indemnify us against all claims, demands, damages, costs, penalties and any liabilities whatsoever caused directly or indirectly by your acts or omissions or your breach of these Terms.

                            <br/><br/>

                            You warrant that your posts, reviews & ratings -

                            <br/><br/>

                            Are not false and are factually correct.
                            <br/>
                            Are based on reasonable grounds and honestly reflect your actual experience.
                            <br/>
                            Are not defamatory or malicious or promote, incite or instruct matters of crime.
                            <br/>
                            Are not illegal or unlawful.<br/>

                            Does not include any inappropriate language.<br/>

                            Does not refer to persons under the age of 18 years old.<br/>

                            Does not include pornographic, sexually explicit, violent, threatening or obscene content.<br/>

                            Does not contain any information that will personally identify any third party unless you have obtained that person’s absolute consent.<br/>

                            Will not transmit any malware, viruses or harmful computer code.<br/>



                            <br/><br/><br/>

                            CANCELLATION AND TERMINATION


                            <br/><br/>
                            WE MAY TERMINATE YOUR ACCESS AT ANY TIME: You agree that we may, in our sole discretion, terminate or suspend your access to the website and Services with or without notice and for any reason, including, without limitation, breach of these Terms. Any suspected fraudulent, abusive or illegal activity may be referred to the appropriate law enforcement authorities.

                            <br/><br/>

                            MODIFICATION OF WEBSITE AND CONTENT

                            <br/><br/>

                            We reserve the right at any time and from time to time to remove, delete, alter, update or amend any Content or the website. In particular, if we believe the Content requires technical updates, to be inappropriate, potentially breach regulations, receive complaints or for any other reason and in our reasonable discretion, we may remove or modify Content at any time without notice. We shall not be liable to you or any third party for any modification when it is required.

                            <br/><br/>

                            LINKING

                            <br/><br/>

                            The website, Mini-site and content available through the website may contain links to other world wide websites, which are completely independent of and to goinglocal.com.au.

                            <br/><br/>

                            We make no representation or warranty as to the accuracy, completeness or authenticity of the information contained in any such website.

                            <br/><br/>

                            Linking to any other world wide websites is at the user’s own risk.

                            <br/><br/>

                            LIABILITY

                            <br/><br/>

                            You agree and acknowledge that we and our directors, officers, employees, independent contractors, agents, consultants, advisors, and other representatives, are not liable for any direct, indirect, consequential or incidental loss or damage which may result from your use of our website, the Content, Services or any information contained on it or linked to it, including, but is not limited to:


                            <br/><br/>
                            your inability to access or use the website;
                            <br/><br/>


                            your reliance on the Content and any information on the website;

                            <br/><br/>

                            access to or inability to use the website; and

                            <br/><br/>

                            your use of the Services or the acts or omissions of an Advertiser that you ask to provide you with their services.

                            <br/><br/>

                            Our liability is governed by the Australian Consumer Law (ACL), including any consumer guarantees provided by the ACL that cannot be excluded or modified.  All other conditions and warranties which may be implied by custom, or statute are expressly excluded by these Terms.

                            <br/><br/>

                            Our failure to exercise or enforce any right or provision of these Terms will not constitute a waiver of such right or provision.

                            <br/><br/>

                            INTELLECTUAL PROPERTY

                            <br/><br/>

                            All custom graphics, icons, logos and service names are registered trademarks, copyright, trade or service marks of Goinglocal™.

                            <br/><br/>

                            All other trademarks or service marks within this website are the property of their respective owners. Nothing in these Terms grants you any right to use any trademark, service mark, logo, and/or our name or any of our Advertisers.
                            <br/><br/>


                            You are solely responsible for obtaining written permission before re-using any copyrighted material that is available on this website. Any unauthorised use of the materials appearing on this website may violate copyright, trademark and other applicable laws and could result in criminal or civil penalties.

                            <br/><br/>

                            DISPUTE RESOLUTION AND GOVERNING LAW

                            <br/><br/>

                            This agreement is governed by the laws of New South Wales, Australia, which are in force from time to time and both parties agree to submit to the exclusive jurisdiction of the Courts of New South Wales.
                        </p>

                    </div>
                </div>
            </div>
        </div>
    )
}