import React,{useState, useEffect} from 'react';
import axios from "axios/index";
import Config from "../../../Config";
import {
    GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import ReCaptchaComponent from '../templates/YourReCaptchaComponentHidden';
import UserSession from "../../../utils/UserSession";

export default function ContactPage(props) {


    const screenheight = window.innerHeight;
    const [name, setName] = useState('');
    const [email, setemail] = useState('');
    const [contact_no, setContact_no] = useState('');
    const [message, setMessage] = useState('');
    const [sent, setSent] = useState(false);
    const [error, setError] = useState("");

    useEffect(()=>{
        window.$(".numOnly").ForceNumericOnly();
    }, []);

    const handleOnChange = (e) =>{
        const name =  e.target.name;
        const value =  e.target.value;

        switch (name){
            case 'name':
                setName(value);
                break;
            case 'contact_no':
                setContact_no(value);
                break;
            case 'email':
                setemail(value);
                break;
            case 'message':
                setMessage(value);
                break;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        window.$("#submitBtn").attr('disabled', true);
        window.$("#submitBtn").text("Sending...");

        const formData = new FormData();
        formData.append('name' ,name );
        formData.append('contact_no' , contact_no );
        formData.append('email' , email );
        formData.append('message' , message );
        formData.append('token' ,  UserSession.getCaptchaToken());

        axios.post(Config.links.send_query, formData)
            .then(function (response) {
                console.log(response);
                window.$("#submitBtn").attr('disabled', false);
                window.$("#submitBtn").text("Submit");

                if(response.data.message === "ok"){
                    setSent(true);
                    setError('');
                }else {
                    setError(response.data.message);
                }

            })
            .catch(function (error) {
                console.log(error);
                window.$("#submitBtn").attr('disabled', false);
                window.$("#submitBtn").text("Submit");
            });







    };

    return(
        <div className="container " id="contact-page" style={{ minHeight: screenheight -194 , paddingTop: 50, paddingBottom: 100}}>


            <GoogleReCaptchaProvider
                reCaptchaKey="6LfSB8ofAAAAACTHI8ZZQJSfgM_hlPe7S5lGXjMz">
                <ReCaptchaComponent/>
            </GoogleReCaptchaProvider>

            <div className="row">
                <div className="col s12 center">
                    <h2>Contact Us</h2>
                    <p>Drop us a message to see how easy increasing your online footprint can be with Goinglocal™.</p>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col s12">
                    {
                        sent ?

                            <div style={{ maxWidth: 550, margin: 'auto'}}>
                                <p className="grey lighten-3 padding-4">
                                    <strong className="black-text">Thank you for reaching us.</strong>
                                    <br/>
                                    Your message has been sent. Please check your email later for other notifications.
                                </p>
                            </div>

                             :
                            <form onSubmit={e => handleSubmit(e)}>
                                <div style={{ maxWidth: 550, margin: 'auto'}}>

                                    <strong>Name<span className="red-text">*</span></strong>
                                    <div className="custom-input mb-2 no-icon">

                                        <input required type="text" onChange={e => handleOnChange(e)} name="name" />
                                    </div>

                                    <strong>Email Address<span className="red-text">*</span></strong>
                                    <div className="custom-input mb-2 no-icon">
                                        <input required type="email" onChange={e => handleOnChange(e)} name="email" />
                                    </div>

                                    <strong>Contact No.<span className="red-text">*</span></strong>
                                    <div className="custom-input mb-2 no-icon">
                                        <input className="numOnly" required  type="text" onChange={e => handleOnChange(e)} name="contact_no" />
                                    </div>



                                    <strong>Your Message</strong>
                                    <textarea onChange={e => handleOnChange(e)} name="message" style={{ border: '2px solid #cccccc',
                                        borderRadius: 5,
                                        height: 150,
                                        width: '100%',
                                        padding: 10}}></textarea>

                                    <div className="center mt-5">
                                        <button id="submitBtn" type="submit" className="btn btn-large green pr-10 pl-10">SUBMIT</button>
                                    </div>



                                    {
                                        error !== "" ?
                                            <div className="card-alert  gradient-45deg-red-pink mt-2" id="resultTxtHolder">
                                                <div className="card-content white-text">
                                                    <i className="material-icons">error</i>
                                                    <p id="resultTxt" style={{ marginTop: 0, marginBottom: 0, display: 'inline-block'}} className="white-text center ml-2">
                                                        { error }
                                                    </p>
                                                </div>
                                            </div>
                                            : ""
                                    }

                                </div>
                            </form>
                    }


                </div>
            </div>

        </div>
    )
}