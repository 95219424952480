import React,{useEffect,useState} from 'react';
import {useParams, useLocation, Link} from "react-router-dom";
import SuggestABusinessForm from '../templates/SuggestABusinessForm';
import axios from 'axios';
import config from "../../../Config";


export default function SuggestABusiness(props){


    const [hassError, setHaserror] = useState(false);
    const [errorMessage, seterrorMessage] = useState("");

    const [business_name, setbusiness_name] = useState("");
    const [business_suburb, setbusiness_suburb] = useState("");
    const [state, setstate] = useState("");
    const [country, set_country] = useState("");
    const [postcode, setpostcode] = useState("");
    const [contact_number, setcontact_number] = useState("");
    const [email, setemail] = useState("");
    const [website, setwebsite] = useState("");
    const [category, setcategory] = useState("");
    const [abn, set_abn] = useState("");
    const [banner, setbanner] = useState(null);
    const [isuploading, setisuploading] = useState(false);
    const [isDoneUploading, setisDoneUploading] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleSubmit = e =>{


        e.preventDefault();

        const formData = new FormData();
        formData.append('name' ,business_name );
        formData.append('suburb' ,business_suburb );
        formData.append('state' , state);
        formData.append('postcode' , postcode);
        formData.append('contact_no' , contact_number);
        formData.append('email' , email);
        formData.append('website' , website);
        formData.append('category' , category);
        formData.append('abn' , abn);
        formData.append('country' ,country );

        if(banner != null){
            formData.append('img', banner );
        }


        setisuploading(true);

        axios.post(config.links.suggestDirectory, formData, {})
            .then(res => {
                console.log(res);
                if(res.status === 200){
                    if(res.data.message === "failed"){
                        setisuploading(false);
                        setErrors(res.data.error);
                    }
                    if(res.data.message === "ok"){
                        setisuploading(false);
                        setisDoneUploading(true);
                    }
                }
            })
            .catch(e =>{
                setisuploading(false);
                setHaserror(true);
                seterrorMessage(e.response.data.message);
            })

    };

    const handleOnChange = e => {
        const { name, value } = e.target;

        switch (name){
            case 'business_name' :
                setbusiness_name(value);
                break;
            case 'business_suburb' :
                setbusiness_suburb(value);
                break;
            case 'state' :
                setstate(value);
                break;
            case 'postcode' :
                setpostcode(value);
                break;
            case 'contact_number' :
                setcontact_number(value);
                break;
            case 'email' :
                setemail(value);
                break;
            case 'website' :
                setwebsite(value);
                break;
            case 'category' :
                setcategory(value);
                break;
            case 'country' :
                set_country(value);
                break;
            case 'banner' :
                setbanner(e.target.files[0]);
                const reader = new FileReader();
                reader.onload = function (event) {

                    window.$('.imagePrev').attr("src" , event.target.result);
                };
                reader.readAsDataURL(e.target.files[0]);
                break;
        }
    };



    return(
        <div id="suggest-business" style={{paddingTop: 50}}>

            {
                isDoneUploading ?
                    <div className="valign-wrapper" style={{ height: '100vh' , maxHeight: 800}}>
                        <div style={{ margin: 'auto'}}>
                            <div className="row">
                                <div className="col s12 center">
                                    <h2>Submitted Successfully!</h2>
                                    <p>Your business suggestion will be listed on going local once approved.</p>
                                    <Link to="/" className="btn-flat mb-1 waves-effect green-text">
                                        <i className="material-icons left">arrow_back</i> Back to Home
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="container">
                        <div className="row">
                            <div className="col s12 center">
                                <h2 className="">Suggest a business</h2>
                                <p>Help improve Goinglocal™ with your local knowledge. Please provide some basic<br/>information about the business.</p>
                            </div>
                        </div>
                        <div className="row " style={{ maxWidth: 800, margin: 'auto', borderTopLeftRadius: 5}}>
                            <div className="col s12 ">
                                <div className="five-px-radius grey lighten-2 card z-depth-0" style={{ marginTop: 0,  padding: 5, paddingLeft: 15, paddingRight: 15}}>
                                    <p style={{fontSize: 14, fontWeight: 500, marginBottom: 0, marginTop: 5}}>Are you a business owner?</p>
                                    <p style={{fontSize: 14, marginTop: 0, marginBottom: 5}}><Link to="" className="green-text">Add your business</Link> for free and get found online now.</p>
                                </div>
                            </div>
                        </div>

                        <div className="container" >
                            <div className="row" style={{maxWidth: 800, margin: 'auto', marginTop: 20, marginBottom: 50}}>
                                <div className="col s12">

                                    <form onSubmit={e => handleSubmit(e)}>
                                        <SuggestABusinessForm isUploading={isuploading} hasError={hassError} errorMessage={errorMessage} errors={errors} handleChange={ e => handleOnChange(e)}/>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
            }






        </div>
    )

}