import React, { useState, useEffect} from "react";
import axios from 'axios';
import {useNavigate, useLocation, Link, useParams, } from "react-router-dom";
import SearchListItem from '../templates/SearchResultListingItem';
import mapicon from '../../../images/map.png';
import config from "../../../Config";


export default function BusinessSearchResult(props) {

    let navigate = useNavigate();


    const [hasResults , sethasResults] = useState(false);
    const [isSearching , setisSearching] = useState(true);
    const [listings , setListings] = useState([]);

    const {location , name} = useParams();



    const onAddBusinessClick = e =>{
        e.preventDefault();
        navigate("/suggest-a-business");
    };


    useEffect(()=>{


        setisSearching(true);
        axios.get(config.links.search_company,  {
            params: {suburb: location, name: name }
        })
            .then(res => {
                console.log(res.data);
                setListings(res.data);
                setisSearching(false);
            })
            .catch(e =>{
                console.log(e);
                setisSearching(false);
            })

    } , []);






    return(
        <div id="business-search-result-page">


            {
                listings.length > 0?

                    <div>


                        <div style={{ marginBottom: 50}}>
                            <div className="container" style={{ paddingTop: 100, paddingBottom: 50}}>
                                <div className="row">
                                    <div className="col s12 center-align ">
                                        <h3>We found {listings.length} {listings.length > 1 ? 'companies' : 'company'} in  {location}</h3>
                                        <p>Is the business you want to add listed below?</p>
                                    </div>
                                </div>
                            </div>

                            <div className="container">
                                <div className="search-results grey lighten-3" style={{ maxWidth: 900, margin: 'auto', padding: 5, borderRadius: 5}}>
                                    {
                                        Array(listings.length).fill(1).map((el, i) =>
                                        <SearchListItem company_name={listings[i].name} location={listings[i].suburb+", "+listings[i].state+", "+listings[i].postcode}/>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    : <div className="container" style={{ paddingTop: 100, paddingBottom: 20}}>
                        <div className="row">
                            <div className="col s12 center-align ">
                                <h3>{ name} is not currently listed on Goinglocal™</h3>
                                <p>Would you like to add the business to Goinglocal™?</p>
                            </div>
                        </div>
                    </div>
            }


            {
                isSearching ?
                    <div className="row">
                        <div className="col s12">
                            <h1>Searching...</h1>
                        </div>
                    </div>
                    :
                    <div className="container" style={{ marginTop: 50, marginBottom: 100}}>
                        <div className="row grey lighten-2 add-business-card-link-holder" style={{maxWidth: 1000, margin: 'auto', padding: 20, borderRadius: 5}}>
                            <div className="col s12 m2 l2 center-align">
                                <img src={mapicon} width={80}/>
                            </div>
                            <div className="col s12 m6 l5 left-align-lg center-align-sm">
                                <h5>Couldn't find the business?</h5>
                                <p>No worries, let's add it now</p>
                            </div>
                            <div className="col s12 m4 l5 right-align-md right-align-lg center-align-sm" style={{ paddingTop: 18}}>
                                <form onSubmit={e => onAddBusinessClick(e)}>
                                    <button type="submit" className="btn btn-large waves-effect waves-light gradient-45deg-cyan-light-green btn-flat white-text "><i className="material-icons left">add</i> ADD BUSINESS</button>
                                </form>
                            </div>
                        </div>
                        <div className="row" style={{marginTop: 20, marginBottom: 100}}>
                            <div className="col s12 center-align">
                                <Link to="/add-business" className="btn-flat mb-1 waves-effect">
                                    <i className="material-icons left">arrow_back</i> Back to Search
                                </Link>
                            </div>
                        </div>
                    </div>
            }


        </div>
    )


}