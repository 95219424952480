import React, { useState} from "react";
import SearchForm from "../templates/SearchForm.Component";
import { useNavigate } from "react-router-dom";
export default function AddBusiness(props) {

    let navigate = useNavigate();
    const [location, setLocation] = useState("");
    const [companyNname, setcompanyNname] = useState("");


    const handleOnSubmit = e =>{
        e.preventDefault();
        const location = e.target.location.value;
        const company_name = e.target.company_name.value;

        navigate("/add-business/find/"+location+"/"+company_name);

    };

    const handleOnchange = e =>{
        const { name, value } = e.target;
        if(name === "location"){
            setLocation(value);
        }

        if(name === "company_name"){
            setcompanyNname(value);
        }
    };

    return (
        <div id="add-business-page">

            <div className="main-search-holder-v2">
                <div className="main-search-holder-wrapper-v2 valign-wrapper">
                    <div className="search-holder">
                        <div className="container ">
                            <div className="row">
                                <div className="col s12">
                                    <h4 className="white-text center-align">
                                        Let’s see if the business is already listed on Goinglocal™
                                    </h4>
                                    <p className="white-text center-align">
                                        Having a business listing on Goinglocal™ means Aussies can review and share their<br/>experiences with the wider community.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className=" container ">
                            <form id="addBusinessSearchForm" onSubmit={ e => handleOnSubmit(e)} method="post">
                                <SearchForm handleChange={e => handleOnchange(e)} handleSubmit={e => handleOnSubmit(e)}/>
                            </form>

                        </div>

                        <div className="container" style={{paddingTop: 20}}>
                            <div className="row">
                                <div className="col center center-align s12 white-text">
                                    <div className="col s12  center-align">

                                        <strong>My business isn’t listed? <a className="light-green-text">Get listed now</a></strong>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );

}