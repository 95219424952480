import * as React from "react";
import logo from '../../../images/logo-hd-white.png';
import {Link} from "react-router-dom";
import Socials from './Socials';

class Footer extends React.Component{

    constructor(props){
        super(props);
        const date = new Date();
        this.state = {currentDate : date}
    }

    render(){
        return(
            <div>
                <div className="footer-content " style={{  paddingBottom: 30, backgroundColor: "#212121"}}>

                    <div className="container">
                        <div className="row">
                            <div className="col s12 m7 l7 ">
                                <div className="row">
                                    <div className="col s12 m4 l4 center-align-sm left-align-lg" style={{  paddingTop: 30}}>
                                        <h5 className="white-text" style={{ fontSize: 20}}>NAVIGATION</h5>
                                        <ul className="white-text">
                                            <li><a href="/" className="white-text">Home</a> </li>
                                            <li>About</li>
                                            <li>Listing</li>
                                            <li><a href="/categories" className="white-text">All Categories</a> </li>
                                        </ul>
                                    </div>
                                    <div className="col s12 m4 l4 center-align-sm left-align-lg" style={{  paddingTop: 30}}>
                                        <h5 className="white-text"  style={{ fontSize: 20}}>BUSINESS</h5>
                                        <ul className="white-text">
                                            <li><a href="/register" className="white-text">Register a business</a></li>
                                            <li>Advertise with us</li>
                                            <li>Handling Reviews</li>
                                            <li>Display Ads</li>
                                        </ul>
                                    </div>
                                    <div className="col s12 m4 l4 center-align-sm left-align-lg" style={{  paddingTop: 30}}>
                                        <h5 className="white-text"  style={{ fontSize: 20}}>HELP & SUPPORT</h5>
                                        <ul className="white-text">
                                            <li><a href="/contact-us" className="white-text">Contact Us</a></li>
                                            <li>Help Centre</li>
                                            <li>FAQ</li>
                                            <li>Tips & Guide</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 m5 l5 ">
                                <div className="row">
                                    <div className="col s12 m6 l6 center-align-sm left-align-lg" style={{  paddingTop: 30}}>
                                        <h5 className="white-text"  style={{ fontSize: 20}}>LEGAL</h5>
                                        <ul className="white-text">
                                            <li>Disclaimer</li>
                                            <li><a href="/terms-and-conditions" className="white-text">Terms & Conditions</a></li>
                                            <li><a href="/terms-of-use" className="white-text">Terms of Use</a></li>
                                            <li>Posting Policy</li>
                                            <li>Cookie Policy</li>
                                            <li>Privacy Policy</li>
                                        </ul>
                                    </div>
                                    <div className="col s12 m6 l6 center-align-sm left-align-lg" style={{  paddingTop: 30}}>
                                        <h5 className="white-text"  style={{ fontSize: 20}}>MOBILE APPS</h5>
                                        <ul className="white-text">
                                            <li>IOS</li>
                                            <li>Android</li>
                                            <li>&nbsp;</li>
                                            <li>FOLLOW US</li>
                                            <li><Socials/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div id="footer" className="black z-depth-1" style={{ paddingTop: 20, paddingBottom: 20}}>




                    <div className="container">
                        <div className="row">
                            <div className="add-sm-margin col s12 m3 l2 center-align-sm left-align-lg left-align-md">
                                <Link to="/"><img src={logo} style={{ width: 130}}/> </Link>

                            </div>

                            <div className="white-text add-sm-margin col s12 m5 l6 center-align-sm left-align-lg left-align-md">
                                Copyright © 2021 - 2022 Goinglocal™<br/>
                                All rights reserved.
                            </div>

                            <div className=" add-sm-margin col s12 m4 l4  center-align-sm right-align-lg right-align-md">
                                <a href="/register"> <button className="btn btn-large adBtn">SIGN UP NOW</button></a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Footer;