
const domain = "https://crm.goinglocal.com.au/";
//const domain = "https://admin.goinglocal.com.au/";
//const domain = "http://localhost/admin.goinglocal/";
const config = {

    links : {
        "asset_link" : domain+"",
        //"GA_TRACKING_ID" : "UA-224678357-3", // Staging
        "GA_TRACKING_ID" : "UA-224678357-2",   // Live

        "location_search" : domain+"search",
        "business_name_search" : domain+"api/searchListing",
        "search_company" : domain+"api/searchCompany",
        "getCategoriesWithPagination" : domain+"api/getCategoriesWithPagination?page=",
        "suggestListing" : domain+"api/suggestListing",
        "searchCategories" : domain+"api/searchCategories",
        "searchCategory" : domain+"api/searchCategory",

        // Authentication Links
        "user_login" : domain+"api/login",
        "user_register" : domain+"api/register",
        "user_logout" : domain+"api/logout",
        "updatePassword" : domain+"api/updatePassword",
        "forgot_password" : domain+"api/forgot-password",
        "change_password" : domain+"api/change-password",
        "resendVerificationEmail" : domain+"api/resendVerificationEmail",
        "send_query" : domain+"api/send_query",

        // User Data Links
        "user_profile" : domain+"api/profiles",
        "createProfile" : domain+"api/createProfile",
        "updateProfile" : domain+"api/updateProfile",
        "subscribeEmail" : domain+"api/subscribeEmail",
        "validateUserData" : domain+"api/validateUserData",
        "registerWithBusiness" : domain+"api/registerWithBusiness",
        "checkIfEmailVerified" : domain+"api/checkIfEmailVerified",

        // Directory
        "directories" : domain+"api/directories",
        "updateWithImage" : domain+"api/updateWithImage",
        "createDirectory" : domain+"api/createDirectory",
        "updateListing" : domain+"api/updateListing",
        "suggestDirectory" : domain+"api/suggestDirectory",
        "getDirectory" : domain+"api/getDirectory",

        "reviews" : domain+"api/reviews",
        "leaveMessage" : domain+"api/leaveMessage",
        "" : domain+"",
    }

};

export default config;