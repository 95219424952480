import React, {useEffect, useState} from 'react';
import signupimg from '../../images/signup-img.png'
import logoBlack from '../../images/logo-hd-black.png'
import {Link, useNavigate, useParams} from "react-router-dom";
import UserSession from '../../utils/UserSession';
import config from "../../Config";
import axios from "axios/index";






export default function ResetPassword(props) {
    const {token} = useParams();
    let navigate = useNavigate();
    useEffect(()=>{
    if(UserSession.getToken()){
            navigate('/');
        }
    }, []);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const _email = urlParams.get('email');

    const [email, setEmail] = useState(_email);
    const [password, setPassword] = useState("");
    const [password_confirmation, set_password_confirmation] = useState("");
    const [errors, setErrors] = useState([]);




    const handleOnChange = (e)=>{
        const name =  e.target.name;
        const value =  e.target.value;
        switch (name){
            case 'email':
                setEmail(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case "password_confirmation":
                set_password_confirmation(value);
                break;
        }

    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        window.$('#submitBtn').attr('disabled' ,  true);
        window.$('#submitBtn').text('Loading...');
        setErrors([]);
        axios.post(config.links.change_password, {
            email: email,
            token: token,
            password: password,
            password_confirmation: password_confirmation,
        })
            .then(function (response) {
                console.log(response);
                window.$('#submitBtn').attr('disabled' ,  false);
                window.$('#submitBtn').text('Reset password');

                if(response.data.message === 'failed'){
                    setErrors(response.data.error);
                }else {
                    window.$('#resultTxt').text(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                window.$('#submitBtn').attr('disabled' ,  false);
                window.$('#submitBtn').text('Reset password');
            });

    };





    return (
        <div className="container " id="register-page" style={{  paddingTop: 50, paddingBottom: 50} }>

            <div className="row">
                <div className="col s12 m12 l10 push-l1   z-depth-5 register-form-main-holder">


                    <div className="row equal-height">
                        <div className="col s12 m6 l6  hide-on-small-and-down center-align-sm left-align-lg" style={{ padding: 40, paddingTop: 60}}>
                            <Link to='/'><img src={logoBlack} style={{ width: 150}}/> </Link>
                            <p>Australia's largest collection of trusted local businesses reviewed by you.</p>

                        </div>
                        <div className="col s12 m6 l6  white padding-4 min-height-100" style={{ paddingTop: 30, paddingBottom: 70}}  >

                            <p style={{ marginTop: 50, marginBottom: 0, fontWeight: 400, color: '#999'}}>Welcome back!</p>
                            <h4 style={{ marginTop: 0, marginBottom: 1}}>Reset password </h4>
                            <p style={{marginTop: 5, marginBottom: 0, fontWeight: 400, color: '#999'}}>Don't have an account? <Link to="/register" className="green-text">Register here</Link></p>

                            <form style={{ marginTop: 30}} onSubmit={e => handleOnSubmit(e)}>

                                <strong>Email Address</strong>
                                <div className="custom-input">
                                    <i className="material-icons dp48">mail_outline</i>
                                    <input required type="email" value={email} onChange={e => handleOnChange(e)} name="email" placeholder="youremail@domain.com..."/>
                                </div>
                                <strong>Password</strong>
                                <div className="custom-input" style={{ marginBottom: 5}}>
                                    <i className="material-icons dp48">lock_outline</i>
                                    <input required  type="password" onChange={e => handleOnChange(e)} name="password" placeholder="type your password here..."/>
                                </div>
                                <strong>Confirm Password</strong>
                                <div className="custom-input" style={{ marginBottom: 5}}>
                                    <i className="material-icons dp48">lock_outline</i>
                                    <input required  type="password" onChange={e => handleOnChange(e)} name="password_confirmation" placeholder="type your password here..."/>
                                </div>
                                {Object.keys(errors).map((key, i) => (

                                    Object.keys(errors[key]).map((key2, j) => (
                                        <p key={i} className="red-text" style={{ marginTop: 0, marginBottom: 0}}>{errors[key][key2]}<br/></p>
                                    ))
                                ))}
                                <p id="resultTxt" style={{ marginTop: 0, marginBottom: 0}} className="green-text "> </p>
                                <br/>
                                <button id="submitBtn" className="btn btn-large green width-100">Reset password</button>
                                <br/>
                                <div className="center ">
                                    <p>Copyright © 2022 Goinglocal™</p>
                                </div>

                            </form>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    )

}