import React, {useEffect, useState, useRef} from 'react';
import 'materialize-stepper/dist/css/mstepper.min.css';
import 'materialize-stepper/dist/js/mstepper';
import './RegisterBusiness';
import TempLogo from "../../../images/temp.png";
import homebanner from "../../../images/homebanner-v2.jpg";
import { Editor } from '@tinymce/tinymce-react';
import {useNavigate} from "react-router-dom/index";
import $ from 'jquery';
import Config from "../../../Config";
import axios from "axios/index";
import UserSession from "../../../utils/UserSession";
import '../css/pricing.css';

export default function BusinessRegister(props) {

    const [business_name, set_business_name] = useState("");
    const [email, set_email] = useState('');
    const [phone_no, set_phone_no] = useState('');
    const [address, set_address] = useState('');
    const [state, set_state] = useState('');
    const [suburb, set_suburb] = useState('');
    const [postcode, set_postcode] = useState('');
    const [category, set_category] = useState('');
    const [website, set_wesbite] = useState('');
    const [abn, set_abn] = useState('');
    const [country, set_country] = useState('Australia');
    const [logo, setLogo] = useState(null);
    const [banner, setBanner] = useState(null);
    const [about, setAbout] = useState('');
    const [errors, setErrors] = useState([]);
    const [errorsAccount, seterrorsAccount] = useState([]);
    const [step, set_step] = useState(1);
    const [account_type, set_account_type] = useState(2);
    const [listLink, set_listLink] = useState("");
    const [isSubscribe, setIsSubscribe] = useState(true);

    const [ownerPhoneNo, setOwnerPhoneNo] = useState("");
    const [ownername, setownername] = useState("");
    const [owneremail, setowneremail] = useState("");
    const [password, setpassword] = useState("");
    const [confirmPassword, setconfirmPassword] = useState("");

    const navigate = useNavigate();
    const editorRef = useRef(null);
    const screenheight = window.innerHeight;

    const onEditorChange = (content)=>{
        setAbout(content);
    };

    useEffect(()=>{

        if(UserSession.getToken()){
            navigate('/user/profile');
            return;
        }

        window.loadCategories();
        window.$("#abn").ForceNumericOnly();
        window.$("#phoneno").ForceNumericOnly();
        window.$("#phone_no").ForceNumericOnly();
        window.$("#phone_number").ForceNumericOnly();
        window.$('#categoryselection').on("change", e=> handleOnchange(e));
        window.$('#suburbSelection').on("change", e=> handleOnchange(e));
        window.$("#subscribeBox").prop('checked' , true);
        window.$("#agreeBox").prop('checked' , true);

        window.$('#suburbSelection').on("select2:selecting", function(e) {
            console.log(e.params.args.data);
            set_state(e.params.args.data.state);
            set_postcode(e.params.args.data.postcode);
        });

        /*$("#step-one").fadeOut(100 , 'linear', function() {
            $("#step-one").addClass("hide");
            $("#step-5").fadeIn(100 , 'linear', function() {
                $("#step-5").removeClass("hide");
            });
        });*/

        window.$("#step-two-form").validate({
            rules: {

                email: {
                    required: true,
                    email:true
                },
                password: {
                    required: true,
                    minlength: 8
                },
                password_confirmation: {
                    required: true,
                    minlength: 8,
                    equalTo: "#password"
                },

            },
            messages: {
                password_confirmation:{
                    equalTo: "Your passwords don't match!",
                    minlength: "Enter at least 8 characters"
                },
            },

            errorElement : 'div',
            errorPlacement: function(error, element) {
                var placement = $(element).data('error red-text');
                if (placement) {
                    $(placement).append(error)
                } else {
                    error.insertAfter(element);
                }
            }
        });


    }, []);

    const onCheckboxClick = (e) => {

        if($('#subscribeBox').is(':checked')){
            setIsSubscribe(true);
        }else {
            setIsSubscribe(true);
        }
    };

    const countChar = (e) => {
        const len =  e.target.value.length;
        window.$('#charNum').text("("+len+"/1500)");
    };

    const handleOnchange = (e)=>{
        const name =  e.target.name;
        const value =  e.target.value;
        switch (name) {
            case "shortDescription":
                setAbout(value);
                break;
            case 'owner_name':
                setownername(value);
                break;
            case 'phone_no':
                setOwnerPhoneNo(value);
                break;
            case 'owner_email':
                setowneremail(value);
                break;
            case 'password_confirmation':
                setconfirmPassword(value);
                break;
            case 'password':
                setpassword(value);
                break;
            case 'name':
                set_business_name(value);
                break;
            case 'email':
                set_email(value);
                break;
            case 'phoneno':
                set_phone_no(value);
                break;
            case 'address':
                set_address(value);
                break;
            case 'state':
                set_state(value);
                break;
            case 'suburb':
                set_suburb(value);
                break;
            case 'postcode':
                set_postcode(value);
                break;
            case 'country':
                set_country(value);
                break;
            case 'website':
                set_wesbite(value);
                break;
            case 'abn':
                set_abn(value);
                break;
            case 'about':
                setAbout(value);
                break;
            case 'category' :
                set_category(value);

                break;
            case 'account_type':
                set_account_type(value);
                break;
            case 'logo' :
                setLogo(e.target.files[0]);
                const reader = new FileReader();
                reader.onload = function (event) {

                    window.$('.imagePrev').attr("src", event.target.result);
                };
                reader.readAsDataURL(e.target.files[0]);
                break;
            case 'banner' :
                setBanner(e.target.files[0]);
                const reader2 = new FileReader();
                reader2.onload = function (event) {

                    window.$('.imagePrev2').attr("src", event.target.result);
                };
                reader2.readAsDataURL(e.target.files[0]);
                break;
        }
    };

    const onSubmitForm1 = (e) => {
        e.preventDefault();
        set_step(2);
        $("#step-one").fadeOut(100 , 'linear', function() {
            $("#step-one").addClass("hide");
            $("#step-two").fadeIn(100 , 'linear', function() {
                $("#step-two").removeClass("hide");
            });
        });
    };

    const onSubmitForm2 = (e) => {
        e.preventDefault();
        set_step(3);


        window.$('#step-two-action-next').attr('disabled' ,  true);
        window.$('#step-two-action-next').text('Loading...');
        axios.post(Config.links.validateUserData, {
            email: owneremail,
            name: ownername,
            password: password,
            password_confirmation: confirmPassword,
        })
            .then(function (response) {
                //console.log(response);
                window.$('#step-two-action-next').attr('disabled' ,  false);
                window.$('#step-two-action-next').html('<i class="material-icons right ">arrow_forward</i>CONTINUE');

                if(response.data.message === 'ok'){
                    seterrorsAccount([]);
                    $("#step-two").fadeOut(100 , 'linear', function() {
                        $("#step-two").addClass("hide");
                        $("#step-three").fadeIn(100 , 'linear', function() {
                            $("#step-three").removeClass("hide");
                        });
                    });
                }else {
                    seterrorsAccount(response.data.error);
                }

            })
            .catch(function (error) {
                console.log(error);
                window.$('#step-two-action-next').attr('disabled' ,  false);
                window.$('#step-two-action-next').html('<i class="material-icons right ">arrow_forward</i>CONTINUE');
                seterrorsAccount(error.response.data.errors);
            });




    };

    const onSubmitForm3 = (e) => {
        e.preventDefault();

        window.$('#step-three-action-next').attr('disabled' ,  true);
        window.$('#step-three-action-next').text('Loading...');
        const formData = new FormData();
        formData.append('account_type' , account_type );
        formData.append('owner_name' , ownername );
        formData.append('owner_phone' , ownerPhoneNo );
        formData.append('owner_email' , owneremail );
        formData.append('password' , password );
        formData.append('password_confirmation' , confirmPassword);
        formData.append('is_subscribe' , isSubscribe);

        formData.append('name' ,business_name );
        formData.append('email' ,email );
        formData.append('contact_no' , phone_no );
        formData.append('website' ,website );
        formData.append('address' ,address );
        formData.append('state' ,state );
        formData.append('postcode' ,postcode );
        formData.append('country' , country );
        formData.append('suburb' ,suburb );
        formData.append('abn' ,abn );
        formData.append('about' ,about );
        formData.append('category' ,category );
        if(logo != null){
            formData.append('img' ,logo );
        }
        if(banner != null){
            formData.append('cover' , banner);
        }


        window.$('#savingLoadingScreen').show();

        axios.post(Config.links.registerWithBusiness, formData)
            .then(function (response) {
                //console.log(response);
                window.$('#savingLoadingScreen').hide();
                window.$('#step-three-action-next').attr('disabled' ,  false);
                window.$('#step-three-action-next').text('SUBMIT');
                if(response.data.message === "ok"){
                    set_listLink("/user/listings/"+response.data.directory.id);
                    $("#step-three").fadeOut(100 , 'linear', function() {
                        $("#step-three").addClass("hide");
                        $("#step-5").fadeIn(100 , 'linear', function() {
                            $("#step-5").removeClass("hide");
                        });
                    });

                    UserSession.setToken(response.data.access_token);
                    UserSession.setUserData(response.data.user);
                    UserSession.setUserProfile(response.data.profile);
                    //navigate('/user/profile', { replace: false });

                }

                if(response.data.message === "failed"){
                    setErrors(response.data.error);
                }



            })
            .catch(function (error) {
                window.$('#savingLoadingScreen').hide();
                 console.log(error);
                window.$('#step-three-action-next').attr('disabled' ,  false);
                window.$('#step-three-action-next').text('SUBMIT');
            });


    };


    const gotolistings = (e) => {
        navigate('/', {replace: true})
    };



    return(
     <div id="upgrade-page" className="">
         <form onSubmit={e => onSubmitForm1(e)} id="step-one-form">
         {/*Step One*/}
         <div className="container hide" id="step-one">
             <div className="row">
                 <div className="col s12 m12 l12 center-align-sm left-align-md left-align-lg" style={{ paddingTop: 20, paddingBottom: 0}}>

                     <h3>Register business</h3>
                     <p>
                         Take advantage of our SEO ranking to help boost your business in local search results.
                         Build trust with customers by adding your business details, images and videos of your products and services.
                         <br/>
                         <br/>
                         Have special access to other Goinglocal™ Business features and offerings. Select below the plan that will best suits your business:
                     </p>

                 </div>
             </div>
             <div className="row">
                 <div className="col s12 m12 l12 " style={{ paddingTop: 20, paddingBottom: 50}}>


                     <div className="row plans-container">
                         <div className="col s12 m4 l4 center " style={{  cursor: 'pointer', padding: 20}}>
                             {/*<div className="z-depth-1 white" style={{ padding: '30px 10px'}}>
                                 <h3 className="mb-3 mt-0">$0/m</h3>
                                 <h5 className="mt-0">Free</h5>
                                 <p className="mb-0">1x Business Listing (Preview Only) </p>
                                 <p  className="mt-0 mb-1">Link to your website</p>
                                 <p  className="mt-0 mb-1">Phone Number Display</p>
                                 <p  className="mt-0 mb-1">Message Activated</p>
                                 <p  className="mt-0">Social Media sharing</p>
                                 <label>
                                     <input checked={true} value={2} onChange={e => handleOnchange(e)} required={true} name="account_type" type="radio" />
                                     <span>Select</span>
                                 </label>
                             </div>*/}


                             <div className="card hoverable animate fadeLeft">
                                 <div className="card-image green waves-effect">
                                     <div className="card-title">FREE</div>
                                     <div className="price">
                                         <sup>$</sup>0
                                         <sub> &nbsp;/<span>mo</span></sub>
                                     </div>
                                     <div className="price-desc"></div>
                                 </div>
                                 <div className="card-content">
                                     <ul className="collection featurelist">
                                         <li className="collection-item">1 x Business Listing</li>
                                         <li className="collection-item">Link to your website</li>
                                         <li className="collection-item">Phone Number Display</li>
                                         <li className="collection-item">Message Activated</li>
                                     </ul>
                                 </div>
                                 <div className="card-action center-align">
                                     <label>
                                         {/*<input checked={true} value={2} onChange={e => handleOnchange(e)} required={true} name="account_type" type="radio" />
                                         */}
                                         <input  type="checkbox" onChange={e => handleOnchange(e)} className="filled-in" checked="checked" />
                                         <span>Subscribed</span>
                                     </label>
                                 </div>
                             </div>

                         </div>

                         <div className="col s12 m4 l4 center" style={{ cursor: 'pointer', padding: 20}}>
                             {/*<h3 className="mb-3 grey-text ">$25/m + GST</h3>
                             <h5 className="mt-0 grey-text">Neighbour</h5>
                             <p className="mb-0">3x Business Listing (Preview Only) </p>
                             <p  className="mt-0 mb-1">Link to your website</p>
                             <p  className="mt-0 mb-1">Phone Number Display</p>
                             <p  className="mt-0 mb-1">Message Activated</p>
                             <p  className="mt-0 mb-1">Social Media sharing</p>
                             <p  className="mt-0 mb-1">Add Photos (Limited of 3)</p>
                             <p  className="mt-0 mb-1">Add videos to your listing ( limited of 1 )</p>
                             <p  className="mt-0 ">3 x Promote special offer</p>
                             <label>
                                 <input disabled={true}  value={3} required={true} onChange={e => handleOnchange(e)} name="account_type" type="radio" />
                                 <span>Coming soon</span>
                             </label>*/}

                             <div className="card z-depth-1 hoverable animate fadeUp">
                                 <div className="card-image waves-effect" style={{ backgroundColor: '#D4AF37'}}>
                                     <div className="card-title">GOLD</div>
                                     <div className="price">
                                         TBA
                                     </div>
                                     <div className="price-desc"></div>
                                 </div>
                                 <div className="card-content">
                                     {/*<ul className="collection featurelist">
                                         <li className="collection-item">3 x Business Listing</li>
                                         <li className="collection-item">Link to your website</li>
                                         <li className="collection-item">Phone Number Display</li>
                                         <li className="collection-item">Message Activated</li>
                                         <li className="collection-item">Share listing via Facebook Twitter and Email</li>
                                         <li className="collection-item">Add photos ( limited of 3 )</li>
                                         <li className="collection-item">Add videos to your listing ( limited of 1 )</li>
                                         <li className="collection-item">3 x Promote special offer</li>
                                         <li className=" mb-10 mt-10 hideSM">&nbsp;</li>
                                         <li className=" mb-8 mt-6 hideSM">&nbsp;</li>
                                     </ul>*/}
                                 </div>
                                 <div className="card-action center-align">
                                     <label>
                                         <input disabled={true}  value={3} required={true} onChange={e => handleOnchange(e)} name="account_type" type="radio" />
                                         <span>Coming soon</span>
                                     </label>
                                 </div>
                             </div>

                         </div>
                         <div className="col s12 m4 l4 center" style={{ cursor: 'pointer', padding: 20}}>
                             {/*<h3 className="mb-3 grey-text ">$75/m + GST</h3>
                             <h5 className="mt-0 grey-text">Citizen</h5>
                             <p className="mb-0">5x Business Listing (Preview Only) </p>
                             <p  className="mt-0 mb-1">Link to your website</p>
                             <p  className="mt-0 mb-1">Phone Number Display</p>
                             <p  className="mt-0 mb-1">Message Activated</p>
                             <p  className="mt-0 mb-1">Social Media sharing</p>
                             <p  className="mt-0 mb-1">Add Photos (Limited of 5)</p>
                             <p  className="mt-0 mb-1">Add videos to your listing ( limited of 2 )</p>
                             <p  className="mt-0 mb-1">5 x Promote special offer</p>
                             <p  className="mt-0 mb-1">1 x Ad slot to our Email Newsletter</p>
                             <p  className="mt-0 mb-1">1300 Number Available on Request</p>
                             <p  className="mt-0 mb-1">1 x QR Code  </p>
                             <p  className="mt-0 mb-1">Monthly Report on your listing performance</p>
                             <label>
                                 <input disabled={true} value={4} required={true} onChange={e => handleOnchange(e)} name="account_type" type="radio" />
                                 <span>Coming soon</span>
                             </label>*/}
                             <div className="card hoverable animate fadeRight">
                                 <div className="card-image  accent-2 waves-effect" style={{ backgroundColor: '#e5e4e2'}}>
                                     <div className="card-title black-text">PLATINUM</div>
                                     <div className="price black-text">
                                         TBA
                                     </div>
                                     <div className="price-desc"></div>
                                 </div>
                                 <div className="card-content">
                                     {/*<ul className="collection featurelist">
                                         <li className="collection-item">5 x Business Listing</li>
                                         <li className="collection-item">Link to your website</li>
                                         <li className="collection-item">Phone Number Display</li>
                                         <li className="collection-item">Message Activated</li>
                                         <li className="collection-item">Share listing via Facebook Twitter and Email</li>
                                         <li className="collection-item">Add photos ( limited of 5 )</li>
                                         <li className="collection-item">Add videos to your listing ( limited of 2 )</li>
                                         <li className="collection-item">5 x Promote special offer</li>
                                         <li className="collection-item">1 x Ad slot to our Email Newsletter</li>
                                         <li className="collection-item">1300 Number Available on Request</li>
                                         <li className="collection-item">1 x QR Code</li>
                                         <li className="collection-item">Monthly Report on your listing performance</li>
                                     </ul>*/}
                                 </div>
                                 <div className="card-action center-align">
                                     <label>
                                         <input disabled={true} value={4} required={true} onChange={e => handleOnchange(e)} name="account_type" type="radio" />
                                         <span>Coming soon</span>
                                     </label>
                                 </div>
                             </div>
                         </div>
                     </div>

                     <div className="mt-5 center-align-sm left-align-lg left-align-md">
                         <button type="submit"  id="step-one-action-next" className="btn btn-flat white-text btn-large green"><i className="material-icons right ">arrow_forward</i>CONTINUE</button>
                     </div>

                 </div>
             </div>
         </div>
         </form>

         <form onSubmit={e => onSubmitForm2(e)} id="step-two-form">
             {/*Step Two*/}
             <div className="container " id="step-two">
                 <div className="row">
                     <div className="col s12 m12 l12" style={{ paddingTop: 20, paddingBottom: 0}}>

                         <h3>Personal Details</h3>
                         <p>
                             There are 2 steps to register your business.<br/>

                             First step, you will need to create a personal account by filling in the details below.<br/>

                             Second step will take you to fill in your Business details.
                         </p>
                     </div>
                 </div>
                 <div className="row " style={{ paddingTop: 0, paddingBottom: 50}}>

                     <div className="row">
                         <div className="col s12 m12 l12 mt-2">
                             <strong>Full Name</strong>
                             <div className="custom-input">
                                 <i className="material-icons dp48">face</i>
                                 <input onChange={e => handleOnchange(e)} required type="text" name="owner_name" placeholder="your name..."/>
                             </div>
                         </div>
                     </div>

                    <div className="row">
                        <div className="col s12 m12 l6 mt-2">
                            <strong>Email Address</strong>
                            <div className="custom-input">
                                <i className="material-icons dp48">mail_outline</i>
                                <input onChange={e => handleOnchange(e)} required type="email" name="owner_email" placeholder="youremail@domain.com..."/>
                            </div>
                        </div>

                        <div className="col s12 m12 l6 mt-2">
                            <strong>Phone No.</strong>
                            <div className="custom-input">
                                <i className="material-icons dp48">phone</i>
                                <input  onChange={e => handleOnchange(e)} required type="text" name="phone_no" id="phone_no"/>
                            </div>
                        </div>

                    </div>
                     <div className="row">
                         <div className="col s12 m12 l6 mt-2">
                             <strong>Password</strong>
                             <div className="custom-input">
                                 <i className="material-icons dp48">lock_outline</i>
                                 <input onChange={e => handleOnchange(e)} required  type="password" id="password" name="password" placeholder="type your password here..."/>
                             </div>
                         </div>

                         <div className="col s12 m12 l6 mt-2">
                             <strong>Confirm Password</strong>
                             <div className="custom-input">
                                 <i className="material-icons dp48">lock_outline</i>
                                 <input onChange={e => handleOnchange(e)} required type="password" id="password_confirmation" name="password_confirmation" placeholder="confirm  your password..."/>
                             </div>
                         </div>
                     </div>

                     <div className="row">
                         <div className="col s12 m12 l6 mt-1">
                             <p>
                                 Subscribe to our Newsletter for regular Goinglocal™ updates with hints and tips on promoting your business, local business case studies, special events and best of all you receive our latest offers and deals.
                             </p>
                             <label>
                                 <input id="subscribeBox" onClick={e => onCheckboxClick(e)}  type="checkbox" name="subscribeBox" className="filled-in"  />
                                 <span>Subscribe to Newsletter</span>
                             </label>
                         </div>
                     </div>

                     <div className="row">
                         <div className="col s12 m12 l6 mt-1">

                             <label>
                                 <input required={true} id="agreeBox" onClick={e => onCheckboxClick(e)}  type="checkbox" name="subscribeBox" className="filled-in"  />
                                 <span>I agree to <a href="/terms-of-use" target="_blank" className="green-text">Terms of Use</a> and <a href="/terms-and-conditions" target="_blank" className="green-text">Terms &  Conditions.</a></span>
                             </label>
                         </div>
                     </div>

                     <div className="row">
                         <div className="col s12 m6 l6 mt-1">
                             {Object.keys(errorsAccount).map((key, i) => (
                                 Object.keys(errorsAccount[key]).map((key2, j) => (

                                     <div className="card-alert  gradient-45deg-red-pink">
                                         <div className="card-content white-text">
                                             <p className="mt-0 mb-0">
                                                 <i className="material-icons">error</i> ERROR : {errorsAccount[key][key2]}</p>
                                         </div>
                                         <button type="button" className="close white-text" data-dismiss="alert" aria-label="Close">
                                             <span aria-hidden="true">×</span>
                                         </button>
                                     </div>

                                 ))
                             ))}
                         </div>
                     </div>


                     <div className="row mt-5">
                         <div className="col s12 m12 16 mt-1 center-align-sm left-align-lg left-align-md">
                             <button type="button" id="step-two-action-back" className="hide btn-flat black-text btn-large no-padding mr-5 transparent"><i className="material-icons left ">arrow_back</i>BACK</button>
                             <button  type="submit" id="step-two-action-next" className=" btn btn-flat white-text btn-large green "><i className="material-icons right ">arrow_forward</i>CONTINUE</button>
                         </div>
                     </div>

                 </div>



             </div>
         </form>

         <form onSubmit={e => onSubmitForm3(e)} id="step-two-form">
         {/*Step tree*/}
         <div className="container hide" id="step-three">
             <div className="row">
                 <div className="col s12 m12 l12" style={{ paddingTop: 20, paddingBottom: 0}}>

                     <h3>Business Details</h3>
                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                 </div>
             </div>
             <div className="row " style={{ paddingTop: 20, paddingBottom: 10}}>

                 <div className="col s12 m6 l6 ">
                     <div className="row">
                         <div className="col s12 m12 12 mt-2">
                             <strong>Business Name<span className="red-text">*</span></strong>
                             <div className="custom-input no-icon">
                                 <input  id="name" onChange={e => handleOnchange(e)} required type="text" name="name" placeholder=""/>
                             </div>
                         </div>

                         <div className="col s12 m12 l12 mt-2" >
                             <strong>Please select the business's primary industry<span className="red-text">*</span></strong>
                             <div className="custom-input no-icon select" >
                                 <select required={true}  className="browser-default" id="categoryselection" name="category">

                                 </select>
                             </div>
                         </div>

                     </div>

                     <div className="row">
                         <div className="col s12 m6 16 mt-2">
                             <strong>Street Address</strong>
                             <div className="custom-input no-icon">
                                 <input  id="address"  type="text" onChange={e => handleOnchange(e)} name="address" placeholder=""/>
                             </div>
                         </div>

                         <div className="col s12 m6 l6 mt-2">
                             <strong>Suburb<span className="red-text">*</span></strong>
                             <div className="custom-input no-icon select">
                                 <select required={true}  onChange={e=> handleOnchange(e)} className="browser-default select2-data-ajax " id="suburbSelection" name="suburb">
                                 </select>
                             </div>
                         </div>
                     </div>

                 </div>

                 <div className="col s12 m6 l6">

                     <div className="row">
                         <div className="col s12 m12 112 mt-2">
                             <strong>Business Business Phone No.<span className="red-text">*</span></strong>
                             <div className="custom-input no-icon">
                                 <input  id="phoneno" required type="text" onChange={e => handleOnchange(e)} name="phoneno" placeholder=""/>
                             </div>
                         </div>

                         <div className="col s12 m12 l12 mt-2">
                             <strong>Business Email Address<span className="red-text">*</span></strong>
                             <div className="custom-input no-icon">
                                 <input  required type="email" onChange={e => handleOnchange(e)} name="email" placeholder=""/>
                             </div>
                         </div>
                     </div>


                     <div className="row">
                         <div className="col s12 m6 16 mt-1">
                             <strong>Business Website</strong>
                             <div className="custom-input no-icon">
                                 <input className="ulrField"  id="name"  type="text" onChange={e => handleOnchange(e)} name="website" placeholder=""/>
                             </div>
                         </div>

                         <div className="col s12 m6 l6 mt-1">
                             <strong>ABN</strong>
                             <div className="custom-input no-icon">
                                 <input type="text" onChange={e => handleOnchange(e)} name="abn" id="abn" placeholder=""/>
                             </div>
                         </div>
                     </div>



                 </div>



             </div>



             {
                 account_type == 2 ?


                     <div className="row">
                         <div className="col s12 m12 l12 " style={{ paddingTop: 0, paddingBottom: 50}}>
                             <strong>Descriptions</strong><br/>
                             <small>Maximum of 1500 characters</small>
                                 <textarea
                                 onChange={e => handleOnchange(e)}
                                 maxlength="1500"
                                 onKeyUp={e => countChar(e)}
                                 name="shortDescription"
                                 style={{ border: '2px solid #cccccc',
                                 borderRadius: 5,
                                 height: 100,
                                 width: '100%',
                                 padding: 10}}></textarea>
                             <small>Count <span id="charNum">(0/1500)</span></small>
                         </div>
                     </div>

                     :

                     <div className="row">
                         <div className="col s12 m12 l12">
                             <span>Descriptions </span>
                             <Editor
                                 apiKey='8r59yeqi0ckti09lyqp6werj0iyp7dloff5ez9cxa1otewoj'
                                 onInit={(evt, editor) => editorRef.current = editor}
                                 selector="textarea#descriptionArea"
                                 placeHolder="Type here..."

                                 onEditorChange={content => onEditorChange(content)}
                                 init={{
                                     height: 500,
                                     menubar: false,
                                     plugins: [
                                         'advlist autolink lists link image charmap print preview anchor',
                                         'searchreplace visualblocks code fullscreen',
                                         'insertdatetime media table paste code help wordcount'
                                     ],
                                     toolbar: 'undo redo | styleselect | formatselect | ' +
                                     'bold italic backcolor | alignleft aligncenter ' +
                                     'alignright alignjustify | bullist numlist outdent indent | ' +
                                     'removeformat | help',
                                     content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                 }}
                             />
                         </div>
                     </div>
             }


             <div className="row">
                 <div className="col s12 m12 l12 " style={{ paddingTop: 20, paddingBottom: 50}}>

                     <h3 className="center-align-sm center-align-md center-align-lg">Make the listing stand out!</h3>
                     <p className="mb-0 center-align-sm center-align-md center-align-lg">Add a logo and  photo cover for your company.</p>


                     <div className="row" style={{ marginTop: 20}}>

                         <div className="col s12 m6 l6 center mt-3">
                             <h5>Company Logo</h5>
                             <label>
                                 <img className="imagePrev bordered-image rounded white" src={ TempLogo} style={{ width: '100%', height : 300 , objectFit : 'contain'}}/>
                                 <br/>
                                 <input type="file" name="logo" id="imgField" onChange={e => handleOnchange(e)}/>
                             </label>
                         </div>

                         <div className="col s12 m6 l6 center mt-3">
                             <h5>Banner Photo</h5>
                             <label>
                                 <img className="imagePrev2 bordered-image rounded" src={ homebanner} style={{ width: '100%', height : 300 , objectFit : 'cover'}}/><br/>
                                 <input type="file" name="banner" id="imgField2" onChange={e => handleOnchange(e)}/>
                             </label>
                         </div>

                     </div>

                     <div className="row mt-5"  style={{ paddingBottom: 50}}>
                         <div className="col s12 m12 112 mt-4 center-align-sm center-align-md center-align-lg">
                             <button type="button" id="step-three-action-back" className=" btn-flat black-text btn-large no-padding mr-5 transparent"><i className="material-icons left ">arrow_back</i>BACK</button>
                             <button type="submit" id="step-three-action-next" className="btn btn-flat white-text btn-large green ">SUBMIT</button>
                         </div>
                     </div>

                     <div className="row">
                         <div className="col s12 m6 l6 push-l3">
                             {Object.keys(errors).map((key, i) => (

                                 Object.keys(errors[key]).map((key2, j) => (

                                     <div key={i} className="card-alert  gradient-45deg-red-pink">
                                         <div className="card-content white-text">
                                             <p className="mt-0 mb-0">
                                                 <i className="material-icons">error</i> ERROR : {errors[key][key2]}</p>
                                         </div>

                                     </div>
                                 ))
                             ))}

                         </div>
                     </div>






                 </div>
             </div>



         </div>
         </form>



         <div className="container  hide center " id="step-5" style={{ minHeight: screenheight - 195}}>

             <div style={{ marginTop: 100}}>
                 <h2 className="center mb-0" >Congratulations!</h2><br/>
                 <p className="mt-0">You have successfully made a business account and upload your first listing.</p>

                 <p className="mt-3 mb-3">
                     We've just sent you an email to verify your account.<br/>
                     Please check your inbox.
                 </p>

                 <button className="btn btn-large green" onClick={e => gotolistings(e)}><i className="material-icons left ">home</i> Go to Homepage</button>
             </div>

         </div>


     </div>
    )

}